.adminBlog {
  flex: 1;
  padding: 100px 50px;
}
.adminBlog > div {
  margin: 50px 0px;
}
.adminBlog > div > h4 {
  margin-bottom: 20px;
}
