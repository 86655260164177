.counter {
  padding: 10px 100px;
  display: flex;
  justify-content: center;
}
.counter > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  max-height: 200px;
  color: #808080;
  background-color: white;
}
.counter > div > div {
  width: 300px;
  height: 300px;
  box-shadow: 2px 2px 10px lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.counter > div > div > div > span {
  font-size: 70px;
  font-weight: 700;
  color: #0866FF;
}
@media screen and (max-width: 1200px) {
  .counter > div {
    flex: 1;
  }
  .counter {
    padding: 120px 0px;
  }
  .counter > div > div {
    width: 70%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 760px) {
  .counter {
    flex-direction: column;
    justify-content: center;
  }
}
