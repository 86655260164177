.subjectList {
}
.subjectList > div:nth-child(1) {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(../../image/pexels-suzy-hazelwood-1130980.jpg);
  padding: 0px 15%;
  background-repeat: no-repeat;
  background-size: cover;
}
.subjectList > div:nth-child(1) > div > div:nth-child(2) {
  display: grid;
  place-items: center;
  min-height: 30vh;
  color: white;
}
.subjectList > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 50px 15%;
  color: gray;
}
