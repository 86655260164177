.cardBox {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  text-transform: capitalize;
  text-align: center;
}
.cardBoxTop > h3 {
  margin: 10px 0px;
}
.cardBox > p:nth-child(2) {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 700;
}
.cardBox > p:nth-child(2) > .MuiSvgIcon-root {
  color: #0866ff;
  margin-right: 1px;
}
.iCanTutor {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.iCanTutor > button {
  background: none;
  border: 1px solid lightgray;
  color: black;
  padding: 0px 10px;
  margin: 3px;
  font-weight: 700;
  border-radius: 100px;
  text-transform: inherit;
}

.cardFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 8px;
  flex-wrap: wrap;
}
.cardFooter > button {
  color: white;
  width: 100%;
  font-weight: 700;
  background-color: #0866ff;
  flex-basis: 120px !important;
}

.cardFooter > button:hover {
  background-color: #003da1;
}

.findTutor > div:nth-child(2) {
  padding: 10px 80px;
  margin: 20px 0px 30px 0px;
}

.findTutor > div > form {
  width: 100%;
  display: flex;
  padding: 0px;
  border-bottom: 1px solid white;
}

.findTutor > div > form > input {
  flex: 1;
  outline: none;
  border: none;
  color: white;
  background: none;
}
.findTutor > div > form > button {
  background: #0866ff;
  margin: 5px;
  text-transform: inherit;
  color: white;
}
.findTutor > div > form > ::placeholder {
  color: lightgray;
}
.verified {
  color: #1877f2 !important;
}
