.about > div:nth-child(1) {
  padding: 0% 15%;
  background-color: rgba(0, 0, 0, 0.671);
}
.about > div:nth-child(2) {
  background-color: #edf2f7;
  height: 200px;
  display: grid;
  place-items: center;
}
.about h3 {
  font-size: 30px;
}
.about > div:nth-child(4) {
  max-width: 1200px;
  padding-bottom: 100px;
  margin: 50px auto;
  padding: 30px;
}
.about > div:nth-child(4) p {
  margin: 20px 0px;
  font-size: 20px;
  text-align: justify;
}

.about img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
@media only screen and (max-width: 760px) {
  .about > div:nth-child(4) {
    padding: 20px;
  }
}
