.topTutor {
  padding: 100px 15%;
  background-color: #ffffff;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23106b40'/%3E%3Cstop offset='1' stop-color='%231aaa66'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-position: top left;
}

.topTutor>div:nth-child(1) {
  text-align: center;
}

.topTutor>div:nth-child(1)>h2 {
  font-size: 35px;
  letter-spacing: 1px;
  color: #404145;
}

.topTutor>div:nth-child(1)>p {
  color: gray;
  margin-bottom: 30px;
  letter-spacing: 1px;
  font-size: 25px;
}

.tutorCard {
  padding: 0px;
  margin: 0px 10px !important;
  border-radius: 0px !important;
  max-width: 350px;
}

.MuiCardMedia-img {
  object-fit: fill !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: gray !important;
  z-index: 100;
}

.slick-prev {
  left: -35px !important;
}

.expert {
  color: #404145 !important;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.tuition_job_home {
  margin-top: 20px;
  text-align: center;
  padding: 100px 10%;
  background-color: white;
}

.tuition_job_home>h2 {
  font-size: 35px;
  letter-spacing: 1px;
  color: #404145;
}

.tuition_job_home>p {
  color: gray;
  margin-bottom: 30px;
  letter-spacing: 1px;
  font-size: 25px;
}

.tuitionJob_btn {
  margin-top: 25px !important;
  background-color: #404145 !important;
  color: white !important;
}

@media only screen and (max-width: 600px) {
  .topTutor {
    padding: 70px 10%;
    background-color: #ffffff;
    background-color: #ffffff;
    background-image: none;
  }
}

.premium-card {
  background: linear-gradient(135deg, #f8f9fa 0%, #eef2f3 50%, #dfe9f3 100%) !important;
  border-radius: 15px !important;
  padding: 20px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
}

.premium-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 15px rgba(0, 0, 0, 0.3);
}

.premium-card-header {
  background-color: #6a82fb;
  color: white;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 15px;
  text-align: center;
}

.premium-card-header h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
}

.premium-card-header small {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
}

.premium-card-body {
  padding: 15px;
}

.premium-card-detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-icon {
  width: 40px;
  height: 40px;
  background-color: #6a82fb;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 1.2rem;
}

.premium-card-detail p {
  font-size: 1.1rem;
  color: #333;
  margin: 0;
}

.premium-card-detail strong {
  color: #6a82fb;
  font-weight: bold;
}

.premium-card-extra {
  margin-top: 15px;
  padding: 15px;
  background-color: #f7f7f7;
  border-left: 5px solid #6a82fb;
  border-radius: 8px;
}

.premium-card-footer {
  margin-top: 0px;
  text-align: center;
}

.premium-card-footer button {
  background: linear-gradient(135deg, #6a82fb, #fc5c7d);
  color: white;
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.premium-card-footer button:hover {
  background-color: #fc5c7d;
  box-shadow: 0px 10px 30px rgba(252, 92, 125, 0.5);
}

/* Responsive Design */
@media (max-width: 768px) {
  .premium-card-header h3 {
    font-size: 1.5rem;
  }

  .premium-card-detail p {
    font-size: 1rem;
  }

  .premium-card-footer button {
    padding: 8px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .premium-card {
    padding: 15px;
  }

  .premium-card-header h3 {
    font-size: 1.3rem;
  }

  .detail-icon {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }

  .premium-card-detail p {
    font-size: 0.9rem;
  }

  .premium-card-footer button {
    padding: 8px 20px;
    font-size: 0.9rem;
  }
}
/* .slick-current{
  width: 350px !important;
} */