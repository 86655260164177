.tuitionBox {
  background-color: #3d4340;
  background-image: linear-gradient(to right, #001158, #757273);
  padding: 10px 10%;
  min-height: 100vh;
}
.tuition_job > div > div > .card {
  margin: 10px 5px;
  min-height: 200px;
}
.tuition_job > div > div > .card > div:nth-child(1) {
  padding: 10px;
  font-weight: 700;
  color: rgb(70, 60, 60);
  border-bottom: 1px solid lightgray;
}
.tuition_job > div > div > .card > div:not(:nth-child(1)) {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  font-weight: 600;
}
.tuition_job > div > div > .card > div > button {
  width: 100%;
  background-color: #0861f2;
  color: white;
}
.filter {
  padding: 20px 5px;
}
.filter > form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.filter > form > select {
  padding: 5px 10px;
  flex: 1;
  border-radius: 5px;
  margin: 5px 5px;
  outline-style: none;
  border: none;
}

.filter > form > button {
  padding: 5px 10px;
  flex: 1;
  outline: none;
  border-radius: 5px;
  margin: 0px 5px;
  background-color: #0866ff;
  cursor: pointer;
  color: white;
  outline-style: none;
  border: none;
}
@media only screen and (max-width: 600px) {
  .tuitionBox {
    padding: 10px 3%;
    min-height: 100vh;
  }
}

.applicants {
  color: #fff;
  margin: 20px auto 40px;
}

.assign-btn-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
