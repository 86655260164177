@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
ul li {
  list-style: none;
}
html {
  scroll-behavior: smooth;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background-color: whitesmoke;
  color: black !important;
  border-radius: 20px;
}

.navigation > div:not(:nth-child(3)) {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigation > div > div {
  flex: 0.9 1;
  display: flex;
  justify-content: space-around;
}
.navigation > div > div > a {
  /* color: rgb(255, 255, 255); */
  font-weight: 700;
  color: black !important;

}

.navigation > a > h2 {
  /* color: #ffffff; */
  /* font-size: 40px ; */
  color: black !important;
display: flex;
align-items: center !important;
justify-content: center !important;
font: normal 40px "Cookie", cursive; /* font won't load without internet// this font calls google font api everytime*/

}
.logo{
  height: 70px;
  width: 70px;
}
.navigationRight > button {
  background-color: #0866ff;
  color: white;
  border-radius: 100px;
  text-transform: inherit;
  font-weight: 700;
  padding: 10px 20px;
}
.navigationRight > button:hover {
  background-color: white;
  color: black;
}
.hamburger {
  cursor: pointer;
  display: none;
}
.hamburger > div {
  width: 30px;
  height: 2px;
  margin: 5px 0px;
  background: gray;
}
.for_mobile {
  display: none !important;
}
a > u {
  color: #340bb9;
  display: flex;
  align-items: center;
  font-weight: bold;
}
@media only screen and (max-width: 1024px) {
  .navigation {
    padding: 10px 20px;
  }
  .hamburger {
    cursor: pointer;
    display: block;
    z-index: 1111;
  }
  .for_desktop {
    display: none !important;
  }
  .for_mobile > .link {
    color: black !important;
  }
  .hamburger__active {
    transform: translate(0%) !important;
  }
  .toggle .line1 {
    transform: translate(-0px, 6px) rotate(-45deg);
  }
  .toggle .line3 {
    transform: translate(-0px, -7px) rotate(45deg);
  }
  .toggle .line2 {
    opacity: 0;
  }
  .no__scroll {
    overflow: hidden;
  }
  .for_mobile {
    display: flex !important;
    position: fixed;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    top: 0px;
    left: 0px;
    height: 100vh;
    background-color: white;
    z-index: 100;
    width: 80%;
    overflow: hidden;
    transform: translate(-100%);
    transition-duration: 0.4s;
  }
  .for_mobile > a {
    font-size: 30px;
    margin: 10px 0px;
    font-weight: bold;
  }
}

.link {
  color: #cd2e2e;
  font-weight: bold;
}

.landing {
  background-image: linear-gradient(to right, #001158, #757273);
  padding: 0% 15%;
}
.landing > div {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 60% 40%;
  margin-top: 60px;
}
.landing > div > div > img {
  width: 500px;
}
.landingLeft {
  margin-top: 100px;
  color: white;
}
.landingLeft > form {
  display: flex;
  align-items: center;
  background-color: white;
  max-width: 600px;
  border-radius: 5px;
  padding-left: 5px;
  margin: 15px 0px;
}
.landingLeft > form > input:nth-child(2) {
  flex: 1 1;
  border: none;
  outline: none;
  padding: 10px 5px;
}
.landingLeft > form > .MuiSvgIcon-root {
  color: gray;
}
::placeholder {
  font-size: 16px;
}
.landingLeft > form > button {
  background-color: #0866FF;
  font-weight: 700;
  text-transform: inherit;
  font-size: 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.landingLeft > h2 {
  color: white;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px black;
}
.chip > small {
  color: white;
  font-weight: 700;
}
.chip > button {
  background: none;
  border: 1px solid lightgray;
  color: white;
  padding: 0px 25px;
  margin: 0px 5px;
  font-weight: 700;
  border-radius: 100px;
  text-transform: inherit;
}
.chip > button:hover {
  background-color: white;
  color: black;
}
@media only screen and (max-width: 1200px) {
  .landing {
    padding: 10px 5%;
    min-height: 70vh;
  }
  .landing > div {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 100%;
    margin-top: 60px;
  }
  .landingRight {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .landing {
    padding:10px 5%;
    min-height: 60vh;
  }
  .landing > div {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 100%;
    margin-top: 5px;
  }
  .landingRight {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .landing {
    padding: 10px 5%;
    min-height: 70vh;
  }
  .landingLeft > h2 {
    color: white;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px black;
  }
  .landingLeft > form {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    padding-left: 5px;
    margin: 25px 0px;
  }
  .landingLeft > form > button {
    background-color: #0866FF;
    font-weight: 700;
    text-transform: inherit;
    font-size: 14px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .chip {
    margin-bottom: 10px;
  }
  .chip > button {
    background: none;
    border: 1px solid lightgray;
    color: white;
    padding: 0px 3px;
    margin: 2px 3px;
    font-weight: 300;
    border-radius: 100px;
    text-transform: inherit;
  }
}

.searchFor {
  background-color: #fafaff;
  display: grid;
  place-items: center;
  min-height: 50vh;
  padding: 100px 0px;
}
.searchFor > div:nth-child(1) {
  text-align: center;
}
.searchFor > div:nth-child(1) > h2 {
  text-align: center;
  font-size: 35px;
  letter-spacing: 1px;
  color: #404145;
}
.searchFor > div:nth-child(1) > p {
  color: gray;
  margin-bottom: 30px;
  letter-spacing: 1px;
  font-size: 25px;
}

.searchFor > div:nth-child(2) {
  padding: 10px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.searchFor > div > div:nth-child(2) {
  padding: 10px 20px;
}
.searchFor > div > div:nth-child(2) > div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  .searchFor > div:nth-child(2) {
    padding: 10px 0px;
    display: block;
    align-items: flex-start;
    justify-content: center;
  }
  .searchFor > div:nth-child(2) > div:nth-child(1) {
    text-align: center;
  }
  .searchFor > div:nth-child(2) > div > img {
    width: 50%;
    text-align: center;
  }
  .searchFor > div > div:nth-child(2){
    display: none;
    
  }
  /* .MuiCardActions-root{
    flex-wrap: wrap;
  } */
}

.topTutor {
  padding: 100px 15%;
  background-color: #ffffff;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23106b40'/%3E%3Cstop offset='1' stop-color='%231aaa66'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-position: top left;
}

.topTutor>div:nth-child(1) {
  text-align: center;
}

.topTutor>div:nth-child(1)>h2 {
  font-size: 35px;
  letter-spacing: 1px;
  color: #404145;
}

.topTutor>div:nth-child(1)>p {
  color: gray;
  margin-bottom: 30px;
  letter-spacing: 1px;
  font-size: 25px;
}

.tutorCard {
  padding: 0px;
  margin: 0px 10px !important;
  border-radius: 0px !important;
  max-width: 350px;
}

.MuiCardMedia-img {
  object-fit: fill !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: gray !important;
  z-index: 100;
}

.slick-prev {
  left: -35px !important;
}

.expert {
  color: #404145 !important;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.tuition_job_home {
  margin-top: 20px;
  text-align: center;
  padding: 100px 10%;
  background-color: white;
}

.tuition_job_home>h2 {
  font-size: 35px;
  letter-spacing: 1px;
  color: #404145;
}

.tuition_job_home>p {
  color: gray;
  margin-bottom: 30px;
  letter-spacing: 1px;
  font-size: 25px;
}

.tuitionJob_btn {
  margin-top: 25px !important;
  background-color: #404145 !important;
  color: white !important;
}

@media only screen and (max-width: 600px) {
  .topTutor {
    padding: 70px 10%;
    background-color: #ffffff;
    background-color: #ffffff;
    background-image: none;
  }
}

.premium-card {
  background: linear-gradient(135deg, #f8f9fa 0%, #eef2f3 50%, #dfe9f3 100%) !important;
  border-radius: 15px !important;
  padding: 20px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
}

.premium-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 15px rgba(0, 0, 0, 0.3);
}

.premium-card-header {
  background-color: #6a82fb;
  color: white;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 15px;
  text-align: center;
}

.premium-card-header h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
}

.premium-card-header small {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
}

.premium-card-body {
  padding: 15px;
}

.premium-card-detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-icon {
  width: 40px;
  height: 40px;
  background-color: #6a82fb;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 1.2rem;
}

.premium-card-detail p {
  font-size: 1.1rem;
  color: #333;
  margin: 0;
}

.premium-card-detail strong {
  color: #6a82fb;
  font-weight: bold;
}

.premium-card-extra {
  margin-top: 15px;
  padding: 15px;
  background-color: #f7f7f7;
  border-left: 5px solid #6a82fb;
  border-radius: 8px;
}

.premium-card-footer {
  margin-top: 0px;
  text-align: center;
}

.premium-card-footer button {
  background: linear-gradient(135deg, #6a82fb, #fc5c7d);
  color: white;
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.premium-card-footer button:hover {
  background-color: #fc5c7d;
  box-shadow: 0px 10px 30px rgba(252, 92, 125, 0.5);
}

/* Responsive Design */
@media (max-width: 768px) {
  .premium-card-header h3 {
    font-size: 1.5rem;
  }

  .premium-card-detail p {
    font-size: 1rem;
  }

  .premium-card-footer button {
    padding: 8px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .premium-card {
    padding: 15px;
  }

  .premium-card-header h3 {
    font-size: 1.3rem;
  }

  .detail-icon {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }

  .premium-card-detail p {
    font-size: 0.9rem;
  }

  .premium-card-footer button {
    padding: 8px 20px;
    font-size: 0.9rem;
  }
}
/* .slick-current{
  width: 350px !important;
} */
.cardBox {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  text-transform: capitalize;
  text-align: center;
}
.cardBoxTop > h3 {
  margin: 10px 0px;
}
.cardBox > p:nth-child(2) {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 700;
}
.cardBox > p:nth-child(2) > .MuiSvgIcon-root {
  color: #0866ff;
  margin-right: 1px;
}
.iCanTutor {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.iCanTutor > button {
  background: none;
  border: 1px solid lightgray;
  color: black;
  padding: 0px 10px;
  margin: 3px;
  font-weight: 700;
  border-radius: 100px;
  text-transform: inherit;
}

.cardFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  grid-gap: 8px;
  gap: 8px;
  flex-wrap: wrap;
}
.cardFooter > button {
  color: white;
  width: 100%;
  font-weight: 700;
  background-color: #0866ff;
  flex-basis: 120px !important;
}

.cardFooter > button:hover {
  background-color: #003da1;
}

.findTutor > div:nth-child(2) {
  padding: 10px 80px;
  margin: 20px 0px 30px 0px;
}

.findTutor > div > form {
  width: 100%;
  display: flex;
  padding: 0px;
  border-bottom: 1px solid white;
}

.findTutor > div > form > input {
  flex: 1 1;
  outline: none;
  border: none;
  color: white;
  background: none;
}
.findTutor > div > form > button {
  background: #0866ff;
  margin: 5px;
  text-transform: inherit;
  color: white;
}
.findTutor > div > form > ::placeholder {
  color: lightgray;
}
.verified {
  color: #1877f2 !important;
}

.subjectList {
}
.subjectList > div:nth-child(1) {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(/static/media/pexels-suzy-hazelwood-1130980.924b80b3.jpg);
  padding: 0px 15%;
  background-repeat: no-repeat;
  background-size: cover;
}
.subjectList > div:nth-child(1) > div > div:nth-child(2) {
  display: grid;
  place-items: center;
  min-height: 30vh;
  color: white;
}
.subjectList > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 50px 15%;
  color: gray;
}

.findTutor {
  background-image: linear-gradient(to right, #001158, #757273);
  background-color: #3d4340;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E"); */
  min-height: 100vh;
  padding: 0px 15%;
  
}
@media only screen and (max-width: 1020px) {
  .findTutor {
    padding: 10px 5%;
  }
  .findTutor > div:nth-child(2) {
    padding: 0px 0px;
    margin: 20px 0px 30px 0px;
  }
  .findTutor > div > form {
    width: 100%;
    display: flex;
    padding: 0px;
    border-bottom: 1px solid white;
  }
  .findTutor > div > form > button {
    background: #0866FF;
    margin: 0px;
    text-transform: inherit;
    color: white;
  }
}

.profile {
  background-image: linear-gradient(to right, #32483d, #8a6e97);
  min-height: 100vh;
  padding: 0px 15% 30px 15%;
}
.profileTop {
  color: white;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.profileTop > div:nth-child(1) {
  border: 3px solid white;
}
.profileTop > div:nth-child(2) {
  color: white !important;
  margin-left: 20px;
}
.profileTop > div > button {
  color: #0866ff;
}
.profileTop > div > div > button {
  background-color: #0866ff;
  color: white;
  text-transform: inherit;
  font-weight: 700;
  margin-right: 10px;
}
.profileTop > div > div {
  display: flex;
  align-items: center;
}
.profileTop > div > div > span {
  display: flex;
  align-items: center;
}
.profileBottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
.profileBottom > div > h3 {
  color: #0866ff;
  letter-spacing: 1px;
}
.profileBottom > div > div > button {
  background: none;
  border: 2px solid #0866ff;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  margin: 30px auto;
}
.profileBottom > div:nth-child(1) > p {
  max-width: 70%;
  margin-top: 15px;
}
.profileBottom > div:nth-child(1) > a > button {
  color: white;
  border: 1px solid white;
  margin-top: 20px;
}
.profileBottom > div:nth-child(2) > ul > li {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.493);
  font-weight: 700;
  font-style: italic;
}
.profileBottom > div:nth-child(2) > ul > li > .MuiSvgIcon-root {
  color: #0866ff;
}
.profileBottom > div:nth-child(2) > ul > li > div:nth-child(2) {
  flex: 1 1;
  margin: 0px 10px;
}
.feed {
  margin-top: 10px;
  max-width: 40%;
}
.feed > h3 {
  color: #0866ff;
}
.feed > div:nth-child(4) > button {
  color: white !important;
  margin-top: 20px;
  border: 1px solid white;
  text-transform: inherit;
}
.modal {
  background-color: whitesmoke;
  width: 50%;
  min-height: 50vh;
  outline: none;
  display: grid;
  grid-template-columns: 30% 70%;
}
.modal > div:nth-child(1) {
  background-image: linear-gradient(to bottom, #b972c7, #ae39d5, #762ad9);
  background-size: cover;
  background-position: center;
  color: white;
  display: grid;
  place-items: center;
  padding: 10px;
}

.verified_user > h4 {
  margin: 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  width: -moz-fit-content;
  width: fit-content;
}
.verified_user > h4 > .MuiSvgIcon-root {
  color: #0866ff;
}
.payPal_box {
  padding: 30px 0px;
  color: white;

  max-width: -moz-fit-content;

  max-width: fit-content;
}
.payPal_box > h4 {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1500px) {
  .modal {
    width: 95%;
    min-height: 50vh;
    outline: none;
    display: grid;
    grid-template-columns: 30% 70%;
  }
}
@media only screen and (max-width: 1200px) {
  .profile {
    padding: 10px 5%;
  }
  .profileTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .profileBottom {
    display: grid;
    grid-template-columns: 100%;
    color: white;
    max-height: -moz-fit-content;
    max-height: fit-content;
    margin-top: 50px;
  }
  .profileBottom > div:nth-child(1) > p {
    max-width: 100%;
    margin-top: 15px;
  }
  .upperFeed {
    display: flex;
  }
  .feed {
    flex: 1 1;
    margin-top: 10px;
    max-width: 100%;
    padding: 10%;
  }
  .modal {
    width: 95%;
    min-height: 50vh;
    outline: none;
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .profileBottom > div > h3 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .modal > div:nth-child(1) h2 {
    font-size: 30px !important;
  }
  .modal > div:nth-child(1) h3 {
    font-size: 15px !important;
  }
  .navigation > a > h2 {
    /* color: #ffffff; */
    font-size: 36px !important;
    color: black !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  }
  .logo{
    height: 62px;
    width: 62px;
  }
}

.form {
  display: grid;
  place-items: center;
  height: 100%;
}
.form > form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  height: 100%;
}
.form > form > button {
  background: #762ad9;
  color: white;
}
.form > form > button:hover {
  opacity: 0.7;
  background: #762ad9;
  color: white;
}

.spinner-container {
  height: 80vh; /* You can adjust this value as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.spinner circle {
  fill: none;
  stroke: #333; /* You can adjust the color */
  stroke-width: 3;
  stroke-dasharray: 150;
  stroke-dashoffset: 150;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 150;
  }
  50% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 150;
    transform: rotate(360deg);
  }
}

.tutorRegi {
  padding: 10px 10%;
  min-height: 100vh;
  background-color: #3d4340;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.tutorRegi > div {
  text-align: center;
}
.tutorRegi > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
}
.tutorRegi > form > div {
  border: 1px solid lightgray;
  padding: 20px;
  box-shadow: 2px 2px 5px lightgray;
  background-color: white;
}
.tutorRegi > form > div > h3 {
  margin: 15px 0px;
}
.tutorRegi > form > div > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.tutorRegi > form > div > div > input {
  flex: 1 1;
  padding: 10px;
  outline: none;
  border: 1px solid lightgray;
  color: gray;
  margin: 10px 5px;
}
.tutorRegi > form > div > div > select {
  flex: 1 1;
  padding: 10px;
  outline: none;
  border: 1px solid lightgray;
  color: gray;
  margin: 10px 5px;
}
.tutorRegi > form > div > div > textarea {
  flex: 1 1;
  padding: 10px;
  outline: none;
  border: 1px solid lightgray;
  color: gray;
  margin: 10px 5px;
}
.two_btn {
  display: flex;
  justify-content: space-around;
}
.step_4 {
  background-color: white;
  margin-top: 20px;
  padding: 50px 10px;
  color: green;
  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .tutorRegi > form > div > div {
    align-items: flex-start;
    flex-direction: column;
  }
  .tutorRegi > form > div > div > input {
    width: 100%;
  }
  .tutorRegi > form > div > div > select {
    width: 100%;
  }
  .tutorRegi > form > div > div > textarea {
    width: 100%;
  }
  .tutorRegi > form > div > div > p {
    display: none;
  }
}

.verification-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  grid-gap: 20px;

  gap: 20px;
}

.verification-btn {
  width: 100%;
  max-width: 250px;
}

.tuitionBox {
  background-color: #3d4340;
  background-image: linear-gradient(to right, #001158, #757273);
  padding: 10px 10%;
  min-height: 100vh;
}
.tuition_job > div > div > .card {
  margin: 10px 5px;
  min-height: 200px;
}
.tuition_job > div > div > .card > div:nth-child(1) {
  padding: 10px;
  font-weight: 700;
  color: rgb(70, 60, 60);
  border-bottom: 1px solid lightgray;
}
.tuition_job > div > div > .card > div:not(:nth-child(1)) {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  font-weight: 600;
}
.tuition_job > div > div > .card > div > button {
  width: 100%;
  background-color: #0861f2;
  color: white;
}
.filter {
  padding: 20px 5px;
}
.filter > form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.filter > form > select {
  padding: 5px 10px;
  flex: 1 1;
  border-radius: 5px;
  margin: 5px 5px;
  outline-style: none;
  border: none;
}

.filter > form > button {
  padding: 5px 10px;
  flex: 1 1;
  outline: none;
  border-radius: 5px;
  margin: 0px 5px;
  background-color: #0866ff;
  cursor: pointer;
  color: white;
  outline-style: none;
  border: none;
}
@media only screen and (max-width: 600px) {
  .tuitionBox {
    padding: 10px 3%;
    min-height: 100vh;
  }
}

.applicants {
  color: #fff;
  margin: 20px auto 40px;
}

.assign-btn-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.login {
  padding: 0px 10%;
  min-height: 100vh;
  background-color: #3d4340;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.login > div {
  display: grid;
  place-items: center;
  min-height: 80vh;
}
.login > div > form > h2 {
  color: white;
  text-align: center;
  font-size: 30px;
  margin: 10px 0px;
  font-weight: 400;
}
.login > div > form {
  background-color: #24313c;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 50px 30px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px #24313c;
}
.login > div > form > input {
  margin: 10px 0px;
  background-color: none;
  background: none;
  outline: none;
  border: 2px solid #a0b3b0;
  color: #a0b3b0;
  padding: 5px;
  font-size: 20px;
}
.login > div > form > input:focus {
  background: none;
  border: 1px solid #1ab188;
}
.login > div > form > button {
  background: #1ab188;
  outline: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  border: none;
  color: white;
  margin-top: 20px;
}

.or-text {
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
}

.register-btn {
  background: transparent !important;
  border: 2px solid #1ab188 !important;
  cursor: pointer !important;
}

@media only screen and (max-width: 768px) {
  .login > div > form {
    min-width: 10px;
  }
}

.mainfooter {
  max-height: 550px;
  background-color: #292c2f;
  color: white;
  font-size: 38pt;
  text-align: center;
  line-height: 550px;
}

/* actual footer starts here  */

footer {
  position: fixed;
  bottom: 0;
}
.footer-distributed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
  margin-top: 0px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer-distributed .footer-left {
  width: 40%;
}

.footer-distributed h3 {
  color: #ffffff;
  font-size: 26px;
  /* font-family: 'SF Pro Display', sans-serif; apple website font// not imported*/
  font: normal 26px "Cookie", cursive; /* font won't load without internet// this font calls google font api everytime*/
  margin: 0;
}

.footer-distributed h3 span {
  color: #FFCC00;
}

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #8f9296;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: #fbfffd;
  text-decoration: none;
}

.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
  color: #ffffff;
  font-size: 14px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* responsive part starts  */

@media (max-width: 700px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}

.shu_Box {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.cardbutton_s,
.card-title_s,
.cardmeta-info_s {
  font-family: "Blinker", sans-serif;
}

.card-title_s {
  color: #fff;
  font-size: 28px;
  margin-bottom: 0.5em;
  line-height: 1.1;
}

.card-text_s,
.cardmeta-info_s {
  color: rgba(255, 255, 255, 0.7);
}

.card-text_s {
  line-height: 1.6;
}

.cardmeta-info_s {
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1rem;
}

.span_s,
.cardbutton_s {
  font-weight: 700;
}

.cardmeta-info_s > span {
  display: block;
  font-size: 1.85em;
}

.cardbutton_s {
  font-size: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 0.55em 2em;
  color: #fff;
  background-color: rgb(11, 148, 63);
}

.cardbutton_s:hover,
.cardbutton_s:focus {
  background-color: rgba(255, 255, 255, 0.6);
}

/* Card Styles */
.card_s,
.card-body_s,
.cardcta_s {
  display: flex;
}
.card_s {
  width: 90%;
  max-width: 880px;
  height: 620px;
  flex-direction: column;
  background-color: #222d4a;
}

.card-header_s {
  flex-basis: 40%;
  object-fit: cover;
  /* When using flex-basis on image, there is a bug in browsers (mostly Chrome) where flex-basis is not applied.*/
  /* To fix it, min width and hight (or whichever is cousing you problems) need to be set to 0. */
  min-width: 0;
  min-height: 0;
}

.card-body_s {
  flex-basis: 60%;
  padding: 2rem;
  flex-direction: column;
}

.cardcta_s {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
}

.cardcta_s p {
  flex: 0 1;
}

/* responsive part */

@media screen and (min-width: 620px) {
  .card-title_s {
    font-size: 36px;
  }

  .card-text_s {
    font-size: 18px;
  }
}

@media screen and (min-width: 720px) {
  .card_s {
    flex-direction: row;
    height: 350px;
  }

  .card-header_s {
    object-position: 20% 30%;
  }
}

.counter {
  padding: 10px 100px;
  display: flex;
  justify-content: center;
}
.counter > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  max-height: 200px;
  color: #808080;
  background-color: white;
}
.counter > div > div {
  width: 300px;
  height: 300px;
  box-shadow: 2px 2px 10px lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.counter > div > div > div > span {
  font-size: 70px;
  font-weight: 700;
  color: #0866FF;
}
@media screen and (max-width: 1200px) {
  .counter > div {
    flex: 1 1;
  }
  .counter {
    padding: 120px 0px;
  }
  .counter > div > div {
    width: 70%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 760px) {
  .counter {
    flex-direction: column;
    justify-content: center;
  }
}

.blog {
}
.blog__top {
  padding: 0px 15% 10px 15%;
  background-color: rgba(0, 0, 0, 0.671);
}
.blog__title {
  background-color: #edf2f7;
  height: 200px;
  display: grid;
  place-items: center;
}
.blog__title h3 {
  font-size: 30px;
}
.blog__container {
  max-width: 1400px;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
  gap: 100px;
}

.blog__card {
  width: 600px;
  box-shadow: 2px 2px 10px lightgray;
}
.blog__card img {
  width: 600px;
  object-fit: fill;
}
.blog__card div {
  padding: 20px;
}
.blog__card button {
  background-color: #0866ff;
  font-weight: 700;
  text-transform: inherit;
  font-size: 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  color: white;
  border-radius: 10px;
}
.blog__card button:hover {
  color: black !important;
}
.blog__card h2 {
  font-size: 30px;
}
.blog__card h3 {
  margin-top: 20px;
}
.blog__card p {
  font-size: 18px;
}
.blog__body {
  max-width: 1000px;
  margin: 0px auto;
  padding: 50px 20px;
  box-shadow: 2px 2px 10px lightgray;
  position: relative;
  top: -100px;
  background-color: white;
}

.blog__img {
  max-width: 1200px;
  margin: 0px auto;
}
.blog__img > img {
  width: 100%;
  margin: 20px 0px;
}
.blog_post_button {
  position: fixed;
  right: 50px;
  bottom: 50px;
}
.blogPost {
  max-width: 1200px;
  box-shadow: 2px 2px 10px lightgray;
  margin: 50px auto;
  padding: 20px;
}
.blogPost form {
  display: flex;
  flex-direction: column;
}
.blogPost form input,
.blogPost form textarea {
  margin: 15px 0px;
  padding: 10px;
  border: 1px solid lightgray;
  outline: none;
}
.wait {
  cursor: progress;
}

.blog__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .blog__container {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    gap: 30px;
    place-items: center;
  }
}

@media only screen and (max-width: 760px) {
  .blog__card {
    width: 80%;
    box-shadow: 2px 2px 10px lightgray;
  }
  .blog__card img {
    width: 100%;
    object-fit: fill;
  }
  .blog__body {
    max-width: 1000px;
    margin: 0px auto;
    padding: 50px 20px;
    box-shadow: 2px 2px 10px lightgray;
    position: relative;
    top: -10px;
    background-color: white;
  }
}

/* MY BLOGS */
.my-blogs {
  min-height: 80vh;
  width: 100%;
}

.img-with-btn {
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 20px;
}

.flex-center svg {
  margin: 0 auto;
}

.error {
  margin: 5px 0;
  color: #dc2626;
  font-size: 14px;
}

.admin {
  background: #f7f8fc;
  display: flex;
}

.adminBlog {
  flex: 1 1;
  padding: 100px 50px;
}
.adminBlog > div {
  margin: 50px 0px;
}
.adminBlog > div > h4 {
  margin-bottom: 20px;
}

.adminDashboard {
  flex: 1 1;
  padding: 100px 50px;
}
.adminDashboard__bottom {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px;
  gap: 0px;
}
.adminDashboard__bottom div {
}
.adminDashboard__card {
  min-width: 400px;
  max-width: 600px;
  background: #ffffff;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 4px;
  padding: 10px;
}
.adminDashboard__card_teacher {
  border-bottom: 0.1px solid lightgray;
  margin: 10px 10px;
  display: flex;
  padding: 20px 0px;
}
.adminDashboard__card_teacher > div:nth-child(2) {
  padding: 0px 20px;
  flex: 1 1;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}
button {
  background-color: #0866FF;
  font-weight: 700;
  text-transform: inherit;
  font-size: 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  color: white;
  border-radius: 10px;
}

.adminDashboard__card_jobs {
  margin: 20px 0px;
  border-bottom: 0.1px solid lightgray;
  padding: 10px 10px;
}

.adminDashboard__card_jobs div {
  display: flex;
  justify-content: space-between;
}

.adminSideBar {
  background: #363740;
  min-width: 255px;
  min-height: 100vh;
  color: #dde2ff;
}
.adminSideBar__link {
  display: grid;
  place-items: center;
  margin: 50px 0px;
}
.adminSideBar__button {
  margin-bottom: 100px;
}
.adminSideBar__button div {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  padding: 10px 7px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}
.MuiSvgIcon-root {
  margin-right: 10px;
}
.active_admin {
  background: #9fa2b442;

  border-left: 3px solid #dde2ff;
}

.adminTuition {
  flex: 1 1;
  padding: 100px 50px;
}
.adminTuition > h3 {
  margin-bottom: 20px;
}

table {
  width: 100%;
  table-layout: fixed;
  overflow-x: scroll;
}
.tbl-header {
  background-color: #0866FF;
}
.tbl-content {
  min-height: 300px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
th {
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
}
td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 16px;
  color: #fff;
  background: #363740;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

/* demo styles */
/* body{
  
} */
section {
  margin: 50px;
}

/* follow me template */
.made-with-love {
  margin-top: 40px;
  padding: 10px;
  clear: left;
  text-align: center;
  font-size: 10px;
  font-family: arial;
  color: black;
}
.made-with-love i {
  font-style: normal;
  color: #f50057;
  font-size: 14px;
  position: relative;
  top: 2px;
}
.made-with-love a {
  color: black;
  text-decoration: none;
}
.made-with-love a:hover {
  text-decoration: underline;
}

/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.about > div:nth-child(1) {
  padding: 0% 15%;
  background-color: rgba(0, 0, 0, 0.671);
}
.about > div:nth-child(2) {
  background-color: #edf2f7;
  height: 200px;
  display: grid;
  place-items: center;
}
.about h3 {
  font-size: 30px;
}
.about > div:nth-child(4) {
  max-width: 1200px;
  padding-bottom: 100px;
  margin: 50px auto;
  padding: 30px;
}
.about > div:nth-child(4) p {
  margin: 20px 0px;
  font-size: 20px;
  text-align: justify;
}

.about img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
@media only screen and (max-width: 760px) {
  .about > div:nth-child(4) {
    padding: 20px;
  }
}

.contact {
  padding: 0 0px 0px;
  display: grid;
  place-items: center;
}
.contact h2 {
  text-align: center;
  font-size: 30px;
  color: #0866ff;
  letter-spacing: 5px;
}

.contact form {
  margin-top: 40px;
  display: grid;
  /* grid-template-columns: 1fr 1fr;
  gap: 10px; */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px lightgray;
  min-width: 400px;
}
.contact form div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact form input {
  padding: 10px;
  outline: none;
  margin-bottom: 20px;
  border: 1px solid lightgray;
}
.contact form textarea {
  padding: 10px;
  outline: none;
  margin-bottom: 10px;
  border: 1px solid lightgray;
}
.form_submit input {
  background-color: #0866ff;
  font-weight: 700;
  text-transform: inherit;
  font-size: 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  color: white;
  border-radius: 10px;
}
.form_submit input,
.form_submit textarea {
  width: 700px;
}
@media only screen and (max-width: 760px) {
  .contact {
    padding: 30px 0px;
    display: grid;
    place-items: center;
  }
  .contact form {
    min-width: 80%;
  }
  .form_submit input,
  .form_submit textarea {
    width: 100%;
  }
}

.reg-container {
  min-height: 100vh;
  background-color: #3d4340;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.reg-container form {
  margin: 0 auto;
  background: #24313c;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  margin-top: 10vh;

  --foreground: #fff;
  --primary: #1ab188;
  --danger: #dc2626;
}

.heading {
  color: var(--foreground);
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 20px;
}

.form-group label {
  color: var(--foreground);
  font-weight: 500;
}

.form-group input {
  outline: none;
  background: transparent;
  border: 1px solid var(--foreground);
  padding: 12px 8px;
  color: var(--foreground);
  border-radius: 4px;
}

.form-group input:focus {
  border: 1px solid var(--primary);
}

.btn {
  outline: none;
  border: none;
  background: var(--primary);
  color: var(--foreground);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 8px;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.15s ease;
}

.btn:hover {
  opacity: 0.9;
}

.btn:focus-visible {
  outline: 1px solid var(--primary);
  outline-offset: 4px;
}

.login-btn {
  background: transparent;
  border: 2px solid var(--primary);
  margin-top: 20px;
}

.or-text {
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
}

.error {
  font-size: 14px;
  color: var(--danger);
}

