.blog {
}
.blog__top {
  padding: 0px 15% 10px 15%;
  background-color: rgba(0, 0, 0, 0.671);
}
.blog__title {
  background-color: #edf2f7;
  height: 200px;
  display: grid;
  place-items: center;
}
.blog__title h3 {
  font-size: 30px;
}
.blog__container {
  max-width: 1400px;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
}

.blog__card {
  width: 600px;
  box-shadow: 2px 2px 10px lightgray;
}
.blog__card img {
  width: 600px;
  object-fit: fill;
}
.blog__card div {
  padding: 20px;
}
.blog__card button {
  background-color: #0866ff;
  font-weight: 700;
  text-transform: inherit;
  font-size: 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  color: white;
  border-radius: 10px;
}
.blog__card button:hover {
  color: black !important;
}
.blog__card h2 {
  font-size: 30px;
}
.blog__card h3 {
  margin-top: 20px;
}
.blog__card p {
  font-size: 18px;
}
.blog__body {
  max-width: 1000px;
  margin: 0px auto;
  padding: 50px 20px;
  box-shadow: 2px 2px 10px lightgray;
  position: relative;
  top: -100px;
  background-color: white;
}

.blog__img {
  max-width: 1200px;
  margin: 0px auto;
}
.blog__img > img {
  width: 100%;
  margin: 20px 0px;
}
.blog_post_button {
  position: fixed;
  right: 50px;
  bottom: 50px;
}
.blogPost {
  max-width: 1200px;
  box-shadow: 2px 2px 10px lightgray;
  margin: 50px auto;
  padding: 20px;
}
.blogPost form {
  display: flex;
  flex-direction: column;
}
.blogPost form input,
.blogPost form textarea {
  margin: 15px 0px;
  padding: 10px;
  border: 1px solid lightgray;
  outline: none;
}
.wait {
  cursor: progress;
}

.blog__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .blog__container {
    grid-template-columns: 1fr;
    gap: 30px;
    place-items: center;
  }
}

@media only screen and (max-width: 760px) {
  .blog__card {
    width: 80%;
    box-shadow: 2px 2px 10px lightgray;
  }
  .blog__card img {
    width: 100%;
    object-fit: fill;
  }
  .blog__body {
    max-width: 1000px;
    margin: 0px auto;
    padding: 50px 20px;
    box-shadow: 2px 2px 10px lightgray;
    position: relative;
    top: -10px;
    background-color: white;
  }
}

/* MY BLOGS */
.my-blogs {
  min-height: 80vh;
  width: 100%;
}

.img-with-btn {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 20px;
}

.flex-center svg {
  margin: 0 auto;
}

.error {
  margin: 5px 0;
  color: #dc2626;
  font-size: 14px;
}
