.adminDashboard {
  flex: 1;
  padding: 100px 50px;
}
.adminDashboard__bottom {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
}
.adminDashboard__bottom div {
}
.adminDashboard__card {
  min-width: 400px;
  max-width: 600px;
  background: #ffffff;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 4px;
  padding: 10px;
}
.adminDashboard__card_teacher {
  border-bottom: 0.1px solid lightgray;
  margin: 10px 10px;
  display: flex;
  padding: 20px 0px;
}
.adminDashboard__card_teacher > div:nth-child(2) {
  padding: 0px 20px;
  flex: 1;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}
button {
  background-color: #0866FF;
  font-weight: 700;
  text-transform: inherit;
  font-size: 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  color: white;
  border-radius: 10px;
}

.adminDashboard__card_jobs {
  margin: 20px 0px;
  border-bottom: 0.1px solid lightgray;
  padding: 10px 10px;
}

.adminDashboard__card_jobs div {
  display: flex;
  justify-content: space-between;
}
