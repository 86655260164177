.form {
  display: grid;
  place-items: center;
  height: 100%;
}
.form > form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  height: 100%;
}
.form > form > button {
  background: #762ad9;
  color: white;
}
.form > form > button:hover {
  opacity: 0.7;
  background: #762ad9;
  color: white;
}
