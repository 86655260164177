* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
ul li {
  list-style: none;
}
html {
  scroll-behavior: smooth;
}
