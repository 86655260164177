.verification-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
}

.verification-btn {
  width: 100%;
  max-width: 250px;
}
